
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { Modal } from 'ant-design-vue';
import storeSistema from '@/store/storeSistema';
import { useTelaBase } from '@/core/composables/TelaBase';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import ColunaGrade from '@/core/components/Tela/ColunaGrade.vue';
import Paginacao from '@/core/components/Tela/Paginacao.vue';
import Icone from '@/core/components/Icone.vue';
import PreviewPdf from '@/core/components/Modal/PreviewPdf.vue';
import Card from '@/core/components/Tela/Card.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import PreferenciasColuna from '@/core/components/Preferencias/ColunaTabela.vue';
import BancoModal from './BancoModal.vue';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ECustomRenderRow, IColumn } from '@/core/models/AntDesign/IColumn';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { EVinculoEmpresa } from '@/core/models/Enumeradores/EEVinculoEmpresa';
import { useGradeBase } from '@/core/composables/GradeBase';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { useCrudBase } from '@/core/composables/CrudBase';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import ServicoBanco from '@/servicos/Cadastros/Financeiro/ServicoBanco';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ETipoAmbiente } from '@/models/Enumeradores/PainelAdministrativo/ETipoAmbiente';
import { IBanco } from '@/models/Entidades/Cadastros/Financeiro/IBanco';
import BuscaGeral from '@/core/components/BuscaAvancada/BuscaGeral.vue';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';

export default defineComponent({
  name: 'BancoIndex',
  components: {
    TituloPadrao,
    ColunaGrade,
    Paginacao,
    Icone,
    Card,
    MensagemSemDados,
    PreferenciasColuna,
    BuscaGeral,
    BancoModal,
    PreviewPdf,
  },
  setup() {
    const {
      telaBase, preencherDadosRota, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados, filtrarPermissaoDadosUsuario,
      defineEmpresasSelecionadasComPermissao, telaOperacaoIncluir, telaOperacaoEditar, apresentarMensagemSucesso, apresentarMensagemAlerta, apresentarMensagemErro,
    } = useTelaBase();

    const { crudBase, exibirModal } = useCrudBase();

    const {
      gradeBase, salvarFiltrosBuscaAvancada, salvarOrdenacaoBuscaAvancada, salvarPersonalizacaoColunas, redefinirPersonalizacaoColunas,
      carregarPreferenciasGrade, preparaParametrosConsulta, salvarTodasPreferencias, preencheCodigosSelecionados,
    } = useGradeBase();

    const servicoBanco = new ServicoBanco();
    telaBase.empresasSelecionadas = [];
    gradeBase.ordenacaoSelecionada = [];

    gradeBase.colunasPadrao = [
      {
        title: 'Código do Banco', dataIndex: 'codigoBanco', key: 'CodigoBanco', width: 150, align: 'center', position: 2, visible: true, ordering: true,
      },
      {
        title: 'Nome', dataIndex: 'nome', key: 'Nome', position: 3, visible: true, ordering: true, customRenderRow: ECustomRenderRow.LinkAcao,
      },
      {
        title: 'Status', dataIndex: 'ativo', key: 'Ativo', position: 4, visible: true, align: 'left', customRenderRow: ECustomRenderRow.TagAtivo, width: 80,
      },
      {
        title: 'Ações', key: 'acoes', position: 5, visible: true, fixed: 'right', width: 100, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
      },
    ];

    gradeBase.colunasMobile = [
      {
        title: 'Banco', dataIndex: '', key: 'Banco', position: 0, visible: true, align: 'left',
      },
    ];

    const state = reactive({
      dados: [] as IBanco[],
      buscaRapida: '',
      exibirPersonalizarColunas: false,
      exibirBuscaAvancada: false,
      exibirPersonalizacaoTela: false,
      arquivosPdf: [] as IArquivoPdf[],
    });

    async function buscarDados() {
      state.dados = [];
      const parametrosConsulta = preparaParametrosConsulta(telaBase.empresasSelecionadas, gradeBase.paginacao.current, gradeBase.paginacao.pageSize, gradeBase.paginacao.total);
      parametrosConsulta.valorBuscaRapida = state.buscaRapida;
      telaBase.carregando = true;

      const listaBancoPaginado = await servicoBanco.buscaAvancada(parametrosConsulta, gradeBase.filtrosAplicados);
      state.dados = listaBancoPaginado.dados;
      gradeBase.paginacaoApi = listaBancoPaginado.metaData;
      telaBase.carregando = false;
    }

    async function imprimir(tipoArquivoRelatorio: ETipoArquivo, codigoRegistro?: number) {
      gradeBase.filtrosAplicados = [];
      const parametrosConsulta = preparaParametrosConsulta(telaBase.empresasSelecionadas, 1, 0, 0);
      let codigosSelecionados: number[] = [];
      if (codigoRegistro !== undefined && codigoRegistro > 0) {
        codigosSelecionados.push(codigoRegistro);
      } else {
        codigosSelecionados = gradeBase.codigosSelecionados;
      }

      telaBase.carregando = true;
      const retornoRelatorio = await servicoBanco.relatorioPadrao(tipoArquivoRelatorio, parametrosConsulta, gradeBase.filtrosAplicados, codigosSelecionados);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        if (tipoArquivoRelatorio === ETipoArquivo.PDF) {
          state.arquivosPdf = [{ nome: '', link: retornoRelatorio.link } as IArquivoPdf];
        } else {
          UtilitarioGeral.downloadArquivo(retornoRelatorio.link);
        }
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }

    async function carregarPreferencias() {
      telaBase.preferencias = await new ServicoSistema().obterPreferenciaRecurso(telaBase.identificadorRecurso, telaBase.empresasSelecionadas);
      if (telaBase.preferencias !== null) {
        carregarPreferenciasGrade(telaBase.preferencias);
      }
    }

    function verificarVisibilidadeConunaAcoes() {
      if (telaBase.permissaoDados.imprimir === false && storeSistema.state.configuracaoApp.tipoAmbiente === ETipoAmbiente.Plataforma) { gradeBase.visibilidadeColunaAcoes = false; }
      gradeBase.visibilidadeColunaAcoes = true;
    }
    onBeforeMount(async () => {
      preencherDadosRota();
      await obterPermissoes(ETipoPermissao.Dados);
      await preencherEmpresasComEstrategiaPermissaoDados(EPermissaoDados.Visualizar, false);
      await defineEmpresasSelecionadasComPermissao();
      telaBase.permissaoDados = await filtrarPermissaoDadosUsuario(telaBase.empresasSelecionadas[0]);
      telaBase.propriedadesConsulta = await servicoBanco.obterPropriedadesConsulta();
      if (telaBase.propriedadesConsulta.length > 0) {
        gradeBase.buscaAvancada.filtros = telaBase.propriedadesConsulta.filter((item) => item.filtro === true);
        gradeBase.buscaAvancada.ordenacao = telaBase.propriedadesConsulta.filter((item) => item.ordenacao === true);
      }
      gradeBase.buscaAvancada.filtrosAdicionados = [];
      gradeBase.buscaAvancada.chaveFiltrosAdicionados = 0;
      await carregarPreferencias();
      verificarVisibilidadeConunaAcoes();
      await buscarDados();
    });

    function novoBanco() {
      exibirModal(telaOperacaoIncluir());
    }

    function editarBanco(codigo: number) {
      exibirModal(telaOperacaoEditar(codigo));
    }

    async function excluirBanco(codigo: number) {
      const retornoExclusao = await servicoBanco.excluir(codigo);
      if (retornoExclusao.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retornoExclusao.mensagem);
        state.dados = state.dados.filter((banco: IBanco) => banco.codigo !== codigo);
      } else if (retornoExclusao.status === EStatusRetornoRequisicao.Alerta) {
        Modal.warning({
          title: 'Não foi possível concluir a exclusão!',
          content: retornoExclusao.mensagem,
        });
      }
    }

    async function confirmaExclusao(banco: IBanco) {
      Modal.confirm({
        title: 'Você confirma a exclusão do Banco:',
        content: `${banco.nome} ?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => { await excluirBanco(banco.codigo); },
      });
    }

    async function sincronizarBanco(acao: EPermissaoDados, codigo: number) {
      if (acao === EPermissaoDados.Incluir || acao === EPermissaoDados.Alterar) {
        const parametrosConsulta: IParametrosConsulta = {} as IParametrosConsulta;

        if (!UtilitarioGeral.validaLista(telaBase.empresasSelecionadas)) {
          telaBase.empresasSelecionadas = storeSistema.getters.empresasUsuarioAutenticado();
        }

        parametrosConsulta.empresas = telaBase.empresasSelecionadas;
        parametrosConsulta.codigosSelecionados = [];

        parametrosConsulta.codigosSelecionados.push(codigo);
        const resultadoBusca = await servicoBanco.buscaAvancada(parametrosConsulta);
        if (UtilitarioGeral.validaLista(resultadoBusca.dados)) {
          if (acao === EPermissaoDados.Incluir) {
            state.dados.push(resultadoBusca.dados[0]);
          } else if (acao === EPermissaoDados.Alterar) {
            const index = state.dados.findIndex((c) => c.codigo === codigo);
            if (index >= 0) {
              state.dados[index] = resultadoBusca.dados[0];
            }
          }
        }
      }
    }

    function exibirApenasParaPainelAdministrativo(): boolean {
      return storeSistema.state.configuracaoApp.tipoAmbiente === ETipoAmbiente.Administracao;
    }

    function apresentarBuscaAvancada() {
      state.exibirPersonalizarColunas = false;
      state.exibirBuscaAvancada = !state.exibirBuscaAvancada;
    }

    function apresentarPersonalizarColuna() {
      state.exibirBuscaAvancada = false;
      state.exibirPersonalizarColunas = !state.exibirPersonalizarColunas;
    }

    function apresentarPersonalizacaoTela() {
      state.exibirPersonalizacaoTela = true;
    }

    async function aplicarFiltros() {
      gradeBase.paginacao.current = 1;
      await buscarDados();
    }

    function textoCodigosSelecionados(): string {
      if (gradeBase.codigosSelecionados.length === 1) { return '1 - Banco selecionado'; }

      if (gradeBase.codigosSelecionados.length > 1) { return `${gradeBase.codigosSelecionados.length} - Bancos selecionados`; }

      return '';
    }

    function obtemDataIndex(coluna: IColumn) {
      if (coluna.dataIndex !== undefined) {
        return coluna.dataIndex;
      }
      return '';
    }
    return {
      storeSistema,
      telaBase,
      gradeBase,
      crudBase,
      state,
      novoBanco,
      editarBanco,
      confirmaExclusao,
      excluirBanco,
      sincronizarBanco,
      apresentarBuscaAvancada,
      apresentarPersonalizarColuna,
      apresentarPersonalizacaoTela,
      aplicarFiltros,
      buscarDados,
      imprimir,
      ECustomRenderRow,
      EVinculoEmpresa,
      ETipoArquivo,
      salvarFiltrosBuscaAvancada,
      salvarOrdenacaoBuscaAvancada,
      salvarPersonalizacaoColunas,
      salvarTodasPreferencias,
      redefinirPersonalizacaoColunas,
      preencheCodigosSelecionados,
      textoCodigosSelecionados,
      obtemDataIndex,
      exibirApenasParaPainelAdministrativo,
    };
  },
});
